import Swiper from 'swiper';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { register } from 'swiper/element/bundle';

// Import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/grid';

register();

const CustomSwiper = (swiperClass, options) => {
  console.log(swiperClass);
  new Swiper(`.${swiperClass}`, options);
};

const initializeSwipers = () => {
  // Instances of swipers in sections
  [
    ['header-swiper', {
      modules: [Navigation, Pagination, Scrollbar],
      direction: "horizontal",
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      mousewheel: false,
      clickable: true,
      speed: 600,
      parallax: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      autoplay: {
        delay: 15000,
      },
    }],
 
    ['structure-swiper', {
      modules: [Navigation, Pagination, Scrollbar],
      direction: "horizontal",
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      mousewheel: false,
      clickable: true,
      speed: 600,
      parallax: false,
      navigation: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      }
    }],
    ['category-swiper', {
      modules: [Navigation, Pagination, Scrollbar],
      direction: "horizontal",
      loop: true,
      centeredSlides: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      mousewheel: false,
      clickable: true,
      speed: 600,
      parallax: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      autoplay: false
    }],
    ['process-swiper', {
      modules: [Navigation, Pagination, Scrollbar],
      direction: "horizontal",
      loop: true,
      centeredSlides: false,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      mousewheel: false,
      clickable: true,
      speed: 600,
      parallax: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet : function (index, className) {
          return '<span class="' + className + '">' + (index + 1) + "</span>";
      }},
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      autoplay: false,
    }],
    ['show-swiper', {
      modules: [Navigation, Pagination, Scrollbar],
      direction: "horizontal",
      loop: false,
      centeredSlides: true,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 0,
      mousewheel: false,
      clickable: true,
      speed: 600,
      parallax: true,
      navigation: false,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      autoplay: {
        delay: 4000,
      },
    }],
  
    ['brand-swiper', {
      modules: [],
      direction: "horizontal",
      loop: false,
      centeredSlides: false,
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 0,
      mousewheel: false,
      clickable: true,
      speed: 1000,
      parallax: false,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      scrollbar: {
        el: ".swiper-scrollbar",
        hide: true,
      },
      autoplay: {
        delay: 3000,
      },
      grid: {
        rows: 2,
        fill: 'row',
      },
    }],
 
    // Ajoutez les autres cas de switch de manière similaire
  ].forEach(([swiperClass, options]) => CustomSwiper(swiperClass, options));
};

initializeSwipers();
