const discoverButtonsEls = document.querySelectorAll('.more-btn')
const articleEls = document.querySelectorAll('article')
const htmlEl = document.querySelector('html')
const articleCloseEls = document.querySelectorAll('.close-btn')
const articleContactEls = document.querySelectorAll('.article-contact')


const close = () => 
{
  htmlEl.classList.add('scrollbar')
  articleEls.forEach((articleEl) => 
  {
    articleEl.classList.remove('show')
  })
}

articleCloseEls.forEach((el) =>{
  el.addEventListener('click',  (_event) => { 
    close()
  })
})

articleContactEls.forEach((el) =>{
  el.addEventListener('click',  (_event) => { 
    close()
  })
})

discoverButtonsEls.forEach((el) =>{
  el.addEventListener('click',  (_event) => {
    htmlEl.classList.remove('scrollbar')
    articleEls.forEach((articleEl) => 
    {
        articleEl.dataset.index === _event.target.dataset.index ?
          articleEl.classList.add('show')
          :
          articleEl.classList.remove('show') 
    })
  })
})