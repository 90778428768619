import env from '../../../env.prod.json'

// DOM Elements
const htmFormFr = document.querySelector('main#fr form'); 
const htmFormEn = document.querySelector('main#en form'); 
const submitBtnFrEl = document.querySelector('main#fr form button');
const submitBtnEnEl = document.querySelector('main#en form button');

const messageEl =  document.querySelector('body .pop-up-message')
const closePopMessageEl = document.querySelector('body .pop-up-message .close');
const redirectBoxEl =  document.querySelector('body .redirect-box')
const closeRedirectBox = document.querySelector('body .redirect-box .close');

const questionRedirectBoxTextEl = document.querySelector('.redirect-box .text')
const confirmRedirectBoxBtnEL = document.querySelector('.redirect-box .confirm-redirect')
const cancelRedirectBoxBtnEL = document.querySelector('.redirect-box .cancel-redirect')
const redirectBoxLinkEl = document.querySelector('.redirect-box a')

const formEls = [htmFormFr,htmFormEn]
const submitBtnEls = [submitBtnFrEl, submitBtnEnEl];

// Global constants
const productUrls =  
{
  // EQUIPMENTS
  PRISMATIC_BATTERY_MODULE_ASSEMBLY_LINE: 'https://huiyaointel.com/industrial-equipment/prismatic-battery-module-assembly-line',
  PRISMATIC_BATTERY_PACK_ASSEMBLY_LINE: 'https://huiyaointel.com/industrial-equipment/prismatic-battery-pack-assembly-line',
  CYLINDRICAL_BATTERY_MODULE_PACK_ASSEMBLY_LINE: 'https://huiyaointel.com/industrial-equipment/cylindrical-battery-module-pack-assembly-line',
  POUCH_BATTERY_MODULE_PACK_ASSEMBLY_LINE: 'https://huiyaointel.com/industrial-equipment/pouch-battery-module-pack-assembly-line',
  // ESS
  PRISMATIC_BATTERY_MODULE: 'https://huiyaointel.com/energy-storage/prismatic-battery-module',
  AIR_COOLED_BATTERY_PACK: 'https://huiyaointel.com/energy-storage/air-cooled-battery-pack',
  LIQUID_COOLED_BATTERY_PACK: 'https://huiyaointel.com/energy-storage/liquid-cooled-battery-pack',
  AIR_COOLED_COMMERCIAL_INDUSTRIAL_ES : 'https://huiyaointel.com/energy-storage/industrial-and-commercial-air-cooled-energy-storage-integrated-cabinet',
  LIQUID_COOLED_COMMERCIAL_INDUSTRIAL_ES: 'https://huiyaointel.com/energy-storage/industrial-and-commercial-liquid-cooled-energy-storage-integrated-cabinet',
  ENERGY_STORAGE_CONTAINER : 'https://huiyaointel.com/energy-storage/energy-storage-container',
  HOUSEHOLD_ESS: 'https://huiyaointel.com/energy-storage/household-energy-storage',
  // PARTNER
  PARTNER : 'https://huiyaointel.com/partnership',
}

// Global variables
let timeoutId = null
let sendingContact = false
let curentProductOption = null

// Utils functions
const toggleSubmit = () =>
{
  for(let btnEl of submitBtnEls) 
  {
    if(sendingContact) 
    {
      btnEl.setAttribute('disabled', true)
      btnEl.classList.add('disabled')
    }
    else 
    {
      btnEl.removeAttribute('disabled')
      btnEl.classList.remove('disabled')
    } 
  }

}

const isEmpty = (value) =>
{
  return (value == null || (typeof value === "string" && value.trim().length === 0));
}

const createTimeOut = () => 
{
 if(!timeoutId){
  timeoutId = setTimeout(
    () =>{
      messageEl.classList.remove('show')

      removeTimeout()
    },
    10000
  )
 }
}

const removeTimeout = () =>
{
  if(timeoutId) {
    clearTimeout(timeoutId)
    timeoutId = null
  }
}

const applyDialog = (message = '', color = 'black') => 
{
 
  const textEl = document.createTextNode(message)
  messageEl.appendChild(textEl) 
  messageEl.style.color = color
  messageEl.classList.add("show")
  sendingContact = false
  toggleSubmit()
  createTimeOut()
}

const setErrorDialog = () => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = 'Une erreur et survenue, message non envoyé, rechargez la page et essayez à nouveau.'
      break
    case 'en' :
      message = 'An error occurred, message not sent, reload the page and try again.'
      break
    case 'zh' :
      message = '发生错误，消息未发送，请重新加载页面，然后重试。'
      break
  }
  applyDialog(message, 'red')
}

const setBadEmailDialog = () => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = "Le format de votre mail n'est pas correct, veuillez corriger pour envoyer votre message."
      break
    case 'en' :
      message = 'The format of your email is not correct, please correct it to send your message.'
      break
    case 'zh' :
      message = '您的电子邮件格式不正确，请更正以发送您的消息。'
      break
  }
  applyDialog(message, 'orange')
}

const setEmptyFieldsDialog = ()  => 
{
  let  message = null
  switch(window.userLanguage) {
    case 'fr' :
      message  = 'Tous les champs sont obligatoires pour envoyer votre message.'
      break
    case 'en' :
      message = 'All fields are required to send your message.'
      break
    case 'zh' :
      message = '发送消息所需的所有字段。'
      break
  }
  applyDialog(message, 'orange')
}

const setSuccessDialog = () => 
  {
    if(Object.keys(productUrls).includes(curentProductOption)) 
    {
      sendingContact = false
      toggleSubmit()
      setRedirectBox()
    }
    else 
    {
      let  message = null
      switch(window.userLanguage) 
      {
        case 'fr' :
          message  = 'Nous avons bien reçu votre message et nous vous répondrons dès que possible.'
          break
        case 'en' :
          message = "We have received your message and will respond as soon as possible."
          break
        case 'zh' :
          message = "您的消息已收到，我们会尽快回复。谢谢您的耐心。"
          break
      }
      applyDialog(message, 'green')
      console.log(`Origin: ${window.location.hostname} - Language: ${window.userLanguage}`)
    }
}
  
const setRedirectBox = () =>
{
  //Set Language 
  let questionText = 'Your message has been successfully sent. We will respond to you as soon as possible. Would you like to see the official product page?'
  let confirmButtonText = 'Open'
  let cancelButtonText =  'Stay here'


  switch(window.userLanguage) {
    case 'fr' :
      questionText = 'Votre message a été envoyé avec succès. Nous vous répondrons dans les plus brefs délais. Souhaitez-vous voir la page officielle du produit ?'
      confirmButtonText = 'Ouvrir'
      cancelButtonText =  'Rester ici'
      break
    case 'en' :
      questionText = 'Your message has been successfully sent. We will respond to you as soon as possible. Would you like to see the official product page?'
      confirmButtonText = 'Open'
      cancelButtonText =  'Stay here'
      break
    case 'zh' :
      questionText = '您的消息已成功发送。我们会尽快回复您。您想查看产品的官方页面吗'
      confirmButtonText = '打开'
      cancelButtonText =  '留在这里'
      break
  }

  questionRedirectBoxTextEl.innerHTML = questionText;
  confirmRedirectBoxBtnEL.innerHTML = confirmButtonText;
  cancelRedirectBoxBtnEL.innerHTML = cancelButtonText;

  console.log(productUrls[curentProductOption])
  redirectBoxLinkEl.href=   productUrls[curentProductOption]

  redirectBoxEl.classList.add('show')
  
}

// Event handlers
const handleSubmit =  async (e) => 
{
  e.preventDefault() 
  sendingContact = true
  toggleSubmit()


  messageEl.classList.remove("show")
  messageEl.lastChild.remove()


  // Select  DOM Form Inputs
  const form = {
    lastnameEl: document.querySelector(`form.${window.userLanguage} #lastname`),
    firstnameEl: document.querySelector(`form.${window.userLanguage} #firstname`),
    mailEl: document.querySelector(`form.${window.userLanguage} #mail`),
    messageEl : document.querySelector(`form.${window.userLanguage} #message`),
    companyEl : document.querySelector(`form.${window.userLanguage} #company`),
    phoneEl : document.querySelector(`form.${window.userLanguage} #phone`),
    productEl : document.querySelector(`form.${window.userLanguage} #product`),
  }

  // Test empty field
  if(
    isEmpty(form.firstnameEl.value) ||
    isEmpty(form.lastnameEl.value) ||
    isEmpty(form.mailEl.value) ||
    isEmpty(form.messageEl.value) ||
    isEmpty(form.companyEl.value) ||
    isEmpty(form.phoneEl.value) ||
    isEmpty(form.productEl.value) 
    ) 
  {
    setEmptyFieldsDialog()
    return
  }
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
  
  // Test mail
  if(!regexEmail.test(mail.value)) 
  {
    setBadEmailDialog()
    return
  }

  fetch(env.API_BASE_PATH + "contact", 
    {
    method: 'POST',
    headers: 
      {
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(
      {
        company: form.companyEl.value,
        lastname: form.lastnameEl.value,
        firstname: form.firstnameEl.value ,
        mail: form.mailEl.value,
        phone: form.phoneEl.value,
        message: form.messageEl.value,
        product: form.productEl.value,
        type: form.productEl.value === "PARTNER" ? "PARTNER" : "CUSTOMER",
        langue: window.userLanguage,
        origin: window.location.hostname,
    
      })
    }
  )

  fetch(env.API_BASE_PATH + "contact/seller", 
    {
    method: 'POST',
    headers: 
      {
        'Content-Type': 'application/json'
      },
    body: JSON.stringify(
      {
        company: form.companyEl.value,
        lastname: form.lastnameEl.value,
        firstname: form.firstnameEl.value ,
        mail: form.mailEl.value,
        phone: form.phoneEl.value,
        message: form.messageEl.value,
        product: form.productEl.value,
        type: form.productEl.value === "PARTNER" ? "PARTNER" : "CUSTOMER",
        langue: window.userLanguage,
        origin: window.location.hostname
      })
    }
  )
  .then((response) => 
    { 
      if(response.status === 201){
        curentProductOption = form.productEl.value;
        setSuccessDialog()
        form.lastnameEl.value = ''
        form.firstnameEl.value = ''
        form.mailEl.value = ''
        form .messageEl.value = ''
        form.phoneEl.value = ''
        form .companyEl.value = ''
        form .productEl.value = ''
      }else {
        setErrorDialog()
      }
    }
  )
  .catch((error) => 
    {
      console.log(error)
      setErrorDialog()
    }
  )     
}

const handleCloseMessageClick = (_e) => 
{
  messageEl.classList.remove('show')
  if(timeoutId) removeTimeout()
} 

const handleCloseRedirectBoxClick = (_e) => 
{
  redirectBoxEl.classList.remove('show')
} 

//Event Listeners
for(let formEl of formEls) 
{
  formEl.addEventListener('submit', handleSubmit) 
}

closePopMessageEl.addEventListener('click', handleCloseMessageClick)
closeRedirectBox.addEventListener('click', handleCloseRedirectBoxClick)
closeRedirectBox.addEventListener('click', handleCloseRedirectBoxClick)
cancelRedirectBoxBtnEL.addEventListener('click', handleCloseRedirectBoxClick)
