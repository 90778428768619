
/**Header logic */ 
const headerEl = document.querySelector("header");

let previewScroll = 0

window.addEventListener('scroll', () => {
  if (window.scrollY < 70) {
    headerEl.style.top = "0";
    headerEl.style.backgroundColor = '#ffffffff';
  } else if (previewScroll > window.scrollY) {
    headerEl.style.top = "0";
  } else {
    headerEl.style.top = "-70px";
    headerEl.style.backgroundColor = '#ffffffaa';
  }
  previewScroll = window.scrollY;
});
