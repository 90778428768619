const subnavItemsEl = document.querySelectorAll('main section.sub-navigation .subnav .subnav-item')
const categoriesSliderEl = document.querySelectorAll('main section.category-slider')

subnavItemsEl.forEach(element => {

  element.addEventListener('click', (_event) => {
    subnavItemsEl.forEach(el =>{
      el.classList.remove('active')
    })
    _event.target.classList.add('active')

    categoriesSliderEl.forEach(el =>{
      _event.target.dataset.index === el.dataset.index ?
        el.classList.add('show')
        :
        el.classList.remove('show')
    })

  })
});

